<template>
  <div v-if="isLoaded">
    <!-- 头图 -->
    <img class="top-image" v-if="data.mainPicture !== ''" :src="data.mainPicture" :onerror="require('@/assets/images/img_fail.png')" />

    <!-- 切换按钮栏 -->
    <div class="category-bar flex-row-between-center">
      <div :class="currentCategoryIndex === index ? 'category-active category-btn' : 'category-btn'" v-for="(item, index) in categories" :key="index" @click="handleChangeCategory(index)">{{ item }}</div>
    </div>

    <!-- 内容块: 相关评价 -->
    <div v-if="currentCategoryIndex === 1">
      <!-- 评价 -->
      <div class="evaluation-box detail-body" v-for="(item, index) in list" :key="index" >
        <!-- 内容 -->
        <div class="detail-content">
          <div class="flex-row-between-center">
            <div class="user-info flex-row-default-center">
              <img v-if="item.user && item.user.avatarUrl" :src="item.user.avatarUrl" />
              <img v-else src="@/assets/images/avatar.png"/>
              <span class="one-line">{{item.user.name}}</span>
            </div>
            <span class="evaluation-time">{{item.createdAt.substring(0, 10)}}</span>
          </div>
          <div class="evaluation-content">{{item.content}}</div>
        </div>
        <!-- 图片块 -->
        <div class="image-block flex-row-default-center" v-viewer>
          <div class="show-image" v-for="(item2, index2) in item.pictures" :key="index2">
            <img class="image" :src="item2" />
          </div>
        </div>
      </div>

      <!-- 底标 -->
      <div class="no-more" v-if="isEnd">没有更多内容了</div>
    </div>

    <template v-else>
      <!-- 内容块: 活动内容 -->
      <div class="detail-body">
        <!-- 标题 -->
        <div class="detail-title">{{data.name}}</div>

        <!-- 副标题 -->
        <div class="detail-subtitle flex-center-center">
          <div class="flex-center-center">
            <span class="iconfont icon-user"></span>
            <span class="read-count one-line">{{data.targetGroupLabel}}</span>
          </div>
          <div class="flex-center-center">
            <span class="iconfont icon-time"></span>
            <span>{{data.createdAt.substring(0, 10)}}</span>
          </div>
        </div>

        <!-- 内容 -->
        <div class="detail-content" v-viewer v-html="data.content"></div>
        <!-- 时间地点信息 -->
        <div class="others">
          <div>活动时间：{{data.activityTime}}</div>
          <div>报名截止：{{data.deadlineAt}}</div>
          <div>活动地点：{{data.location}}</div>
          <div>活动费用：{{data.isFree ? "免费" : data.cost + "元" }}</div>
        </div>
      </div>

      <!-- （取消）报名按钮 -->
      <div v-if="data.status === 'Closed'" class="closed button-one">已结束报名</div>
      <div v-else-if="data.status === 'End'" class="end button-one">活动已结束</div>
      <template v-else>
        <div v-if="!data.isSignup" class="button-one" @click="handleSignup">立即报名</div>
        <div v-else class="cancel-signup button-one" @click="handleCancelSignup">取消报名</div>
      </template>
    </template>
  </div>
</template>

<script>
import { MessageBox, Toast } from 'mint-ui'
import loading from '@/mixins/loading'
import page from '@/mixins/page'

export default {
  name: 'ActivityDetail',

  data () {
    return {
      currentCategoryIndex: 0,
      categories: ['活动内容', '相关评论'],
      activityID: '',
      data: {},
      list: [],
      currentPage: 1,
      pageSize: 8,
      isEnd: false
    }
  },

  mixins: [loading, page],

  created () {
    this.showLoading()
    this.activityID = this.$route.query.id
  },

  mounted () {
    this.getData()
  },

  beforeRouteLeave (to, from, next) {
    to.meta.keepAlive = to.path === '/activity-list'
    next()
  },

  methods: {
    // 修改分类
    handleChangeCategory (index) {
      if (this.currentCategoryIndex === index) return
      this.currentCategoryIndex = index
      if (index === 1) {
        this.initPage()
        this.getEvaluationData()
      }
    },
    // 获取数据
    getData () {
      this.api.getActivityByID({ id: this.activityID }).then(res => {
        const data = res.data
        switch (data.targetGroup) {
          case 'Public':
            data.targetGroupLabel = '所有人'
            break
          case 'Member':
            data.targetGroupLabel = '所有会员'
            break
          case 'MemberGrade':
            data.targetGroupLabel = data.memberGrade.name
        }
        this.data = res.data
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getData() })
      }).finally(() => {
        this.hideLoading()
      })
    },
    // 获取评论情况
    getEvaluationData () {
      this.api.getAllActivityEvaluationByActivityID({ id: this.activityID, currentPage: this.currentPage, pageSize: this.pageSize }).then(res => {
        if (res.data.length === 0 || res.data.length < this.pageSize) this.isEnd = true
        this.list = [...this.list, ...res.data]
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.getEvaluationData() })
      }).finally(() => {
        this.hideLoading()
      })
    },
    // 报名
    handleSignup () {
      const userInfo = this.$store.state.userInfo
      switch (this.data.targetGroup) {
        case 'Member':
          if (!userInfo.isMember) return Toast('该活动只有会员才可报名')
          break
        case 'MemberGrade':
          if (userInfo.memberGrade.name !== this.data.memberGrade.name) return Toast(`该活动只有${this.data.memberGrade.name}才可报名`)
          break
      }
      this.$router.push({ path: '/activity-signup', query: { id: this.activityID } })
    },
    // 取消报名
    handleCancelSignup () {
      MessageBox.confirm('是否确认取消报名？').then(() => {
        this.cancelSignup()
      }).catch(() => {})
    },
    // 确定取消报名
    cancelSignup () {
      this.api.cancelSignupActivity({ id: this.activityID }).then(res => {
        Toast(res.msg || '取消报名成功')
        this.data.isSignup = false
      }).catch(err => {
        if (err.status === 401) this.$store.commit('addLoginCallBack', () => { this.cancelSignup() })
      })
    },
    // 触底函数
    hitBottom () {
      if (this.isEnd || this.currentCategoryIndex !== 1) return
      this.currentPage++
      this.getEvaluationData()
    }
  }
}
</script>

<style scoped>
.top-image {
  margin-bottom: 0;
}

.category-bar {
  padding: 0 2vw;
}
.category-btn {
  width: 47vw;
}

.others {
  font-size: 14px;
  color: #666;
}
.others > div {
  margin-top: 5px;
}

.cancel-signup {
  background: var(--secondary-color);
}
.closed {
  background: #333;
  cursor: pointer;
}
.end {
  background: #aaa;
  cursor: pointer;
}
.detail-content >>> img {
  display: block;
  max-width: 100%;
}

.no-more {
  margin-top: 15px;
}
.evaluation-box {
  margin-bottom: 8px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #eeeeee;
}
.user-info > img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
  box-shadow: 2px 2px 2px #eeeeee;
}
.user-info > span {
  font-size: 16px;
  width: calc(100vw - 40px - 15px - 120px);
}
.evaluation-time {
  font-size: 13px;
  color: #666666;
}
.evaluation-content {
  margin-top: 15px;
  color: var(--light-font-color);
}

.icon-time, .icon-user {
  margin: 0 3px 0 10px;
}
</style>
